<template>
  <div class="q-w360 q-fit">
    <fade-in-transition-wrap>
      <question-with-error-wrap v-if="pregnantVisible" :error="getFieldsError(['pregnant'])">
        <single-answer-question
          id="pregnant"
          title="label.pregnant"
          :value="pregnant"
          :options="$options.yesNoResponseOptions"
          yes-no
          @input="onFieldChange('pregnant', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="menopausalStateVisible"
        :class="{ 'q-mt24 q-mt32-md': pregnantVisible }"
        :error="getFieldsError(['menopausalState'])"
      >
        <single-answer-question
          id="menopausal-state"
          title="label.menopausalState"
          :value="menopausalState"
          :options="$options.menopausalStateOptions"
          @input="onFieldChange('menopausalState', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="takingHRTVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['takingHRT'])"
      >
        <single-answer-question
          id="taking-hrt"
          title="label.takingHRT"
          :value="takingHRT"
          :options="$options.yesNoResponseOptions"
          yes-no
          @input="onFieldChange('takingHRT', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="takingBirthControlPillVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['takingBirthControlPill'])"
      >
        <single-answer-question
          id="taking-birth-control"
          title="label.takingBirthControlPill"
          :value="takingBirthControlPill"
          :options="$options.yesNoResponseOptions"
          yes-no
          @input="onFieldChange('takingBirthControlPill', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="birthControlPillNameVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['birthControlPillName'])"
      >
        <broad-answer-question
          id="birth-control-pill"
          title="label.birthControlPillName"
          :value="birthControlPillName"
          @input="onFieldChange('birthControlPillName', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="usesIUDVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['usesIUD'])"
      >
        <single-answer-question
          id="uses-iud"
          title="label.usesIUD"
          :value="usesIUD"
          :options="$options.yesNoResponseOptions"
          yes-no
          @input="onFieldChange('usesIUD', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap.vue';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion.vue';
import BroadAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/BroadAnswerQuestion.vue';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { MENOPAUSAL } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const MENOPAUSAL_STATE_OPTIONS = [
  {
    value: MENOPAUSAL.PERIMENOPAUSAL,
    text: 'menopausalState.perimenopausal',
    hint: 'gynecologicalHistory.perimenopause'
  },
  {
    value: MENOPAUSAL.MENOPAUSAL,
    text: 'menopausalState.menopausal',
    hint: 'gynecologicalHistory.menopause'
  },
  {
    value: MENOPAUSAL.POSTMENOPAUSAL,
    text: 'menopausalState.postmenopausal',
    hint: 'gynecologicalHistory.postmenopause'
  },
  { value: MENOPAUSAL.NO, text: 'menopausalState.no' }
];

export default {
  name: 'GynecoHistoryTemplate',
  components: {
    FadeInTransitionWrap,
    BroadAnswerQuestion,
    SingleAnswerQuestion,
    QuestionWithErrorWrap
  },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  menopausalStateOptions: MENOPAUSAL_STATE_OPTIONS,
  props: {
    pregnant: {
      type: String,
      default: ''
    },
    pregnantVisible: {
      type: Boolean,
      default: false
    },
    menopausalState: {
      type: String,
      default: ''
    },
    menopausalStateVisible: {
      type: Boolean,
      default: false
    },
    takingHRT: {
      type: String,
      default: ''
    },
    takingHRTVisible: {
      type: Boolean,
      default: false
    },
    takingBirthControlPill: {
      type: String,
      default: ''
    },
    takingBirthControlPillVisible: {
      type: Boolean,
      default: false
    },
    birthControlPillName: {
      type: String,
      default: ''
    },
    birthControlPillNameVisible: {
      type: Boolean,
      default: false
    },
    usesIUD: {
      type: String,
      default: ''
    },
    usesIUDVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
