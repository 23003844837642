<template>
  <gyneco-history-template
    :pregnant="pregnant"
    :pregnant-visible="pregnantVisible"
    :menopausal-state="menopausalState"
    :menopausal-state-visible="menopausalStateVisible"
    :taking-h-r-t="takingHRT"
    :taking-h-r-t-visible="takingHRTVisible"
    :taking-birth-control-pill="takingBirthControlPill"
    :taking-birth-control-pill-visible="takingBirthControlPillVisible"
    :birth-control-pill-name="birthControlPillName"
    :birth-control-pill-name-visible="birthControlPillNameVisible"
    :uses-i-u-d="usesIUD"
    :uses-i-u-d-visible="usesIUDVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import GynecoHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/gyneco-history/GynecoHistoryTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';
import { BOOLEAN, MENOPAUSAL } from '@/modules/questionnaire/api/constants';

const AGES = {
  FOURTEEN: 14,
  SEVENTEEN: 17,
  THIRTY_FOUR: 34,
  THIRTY_FIVE: 35,
  FIFTY: 50
};

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  field('age'),
  requiredField('pregnant'),
  requiredField('menopausalState'),
  requiredField('takingHRT'),
  requiredField('takingBirthControlPill'),
  field('birthControlPillName'),
  requiredField('usesIUD')
];

export default {
  name: 'GynecoHistory',
  components: {
    GynecoHistoryTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    pregnantVisible() {
      return this.age >= AGES.SEVENTEEN && this.age < AGES.FIFTY;
    },
    menopausalStateVisible() {
      return (this.age > AGES.THIRTY_FOUR && this.isNotPregnant) || this.isAfterFiftyYearsAge;
    },
    takingHRTVisible() {
      return this.isMenopausal;
    },
    takingBirthControlPillVisible() {
      return this.canBeTakingBirthControlPill;
    },
    birthControlPillNameVisible() {
      return !!this.takingBirthControlPill && yes(this.takingBirthControlPill);
    },
    usesIUDVisible() {
      return this.canBeTakingBirthControlPill && no(this.takingBirthControlPill);
    },
    isNotPregnant() {
      return no(this.pregnant);
    },
    isMenopausal() {
      return (
        this.menopausalState !== MENOPAUSAL.NO &&
        Object.values(MENOPAUSAL).includes(this.menopausalState)
      );
    },
    canBeTakingBirthControlPill() {
      const hasNoMenopausalState =
        this.menopausalState === MENOPAUSAL.NO || this.age < AGES.THIRTY_FIVE;

      return (
        (this.isNotPregnant && hasNoMenopausalState && this.age > AGES.FOURTEEN) || this.isTeenager
      );
    },
    isTeenager() {
      return this.age > AGES.FOURTEEN && this.age < AGES.SEVENTEEN;
    },
    isAfterFiftyYearsAge() {
      return this.age >= AGES.FIFTY;
    }
  },
  watch: {
    pregnant(newValue, oldValue) {
      this.menopausalState = '';
      this.takingHRT = '';
      this.takingBirthControlPill = '';
      this.birthControlPillName = '';
      this.usesIUD = '';

      if (!oldValue && newValue === BOOLEAN.YES) {
        this.showNextStep();
      } else if (newValue === BOOLEAN.NO) {
        this.scrollTo(this.age < AGES.THIRTY_FIVE ? '#taking-birth-control' : '#menopausal-state');
      }
    },
    async menopausalState(newValue) {
      this.takingHRT = '';
      this.takingBirthControlPill = '';
      this.birthControlPillName = '';
      this.usesIUD = '';

      const isNewValueEqualToNo = newValue === MENOPAUSAL.NO;

      if (this.isAfterFiftyYearsAge && isNewValueEqualToNo) {
        this.showNextStep();

        return;
      }

      this.scrollTo(!isNewValueEqualToNo ? '#taking-hrt' : '#taking-birth-control');
    },
    takingHRT(newValue) {
      this.takingBirthControlPill = '';
      this.birthControlPillName = '';
      this.usesIUD = '';

      if (newValue) {
        this.showNextStep();
      }
    },
    takingBirthControlPill(newValue) {
      this.birthControlPillName = '';
      this.usesIUD = '';

      this.scrollTo(yes(newValue) ? '#birth-control-pill' : '#uses-iud');
    },
    usesIUD(newValue, oldValue) {
      if (!oldValue) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'pregnant') {
        return this.pregnantVisible;
      }

      if (fieldName === 'menopausalState') {
        return this.menopausalStateVisible;
      }

      if (fieldName === 'takingHRT') {
        return this.takingHRTVisible;
      }

      if (fieldName === 'takingBirthControlPill') {
        return this.takingBirthControlPillVisible;
      }

      if (fieldName === 'birthControlPillName') {
        return this.birthControlPillNameVisible;
      }

      if (fieldName === 'usesIUD') {
        return this.usesIUDVisible;
      }

      return true;
    }
  }
};
</script>
